import { NEW_MESSAGE, UPDATE_MESSAGES, UPDATE_SINGLE_MESSAGES, LOAD_MESSAGE, SYSTEM_MESSAGE, INITIALIZE_LANDING_PAGE, LOAD_MESSAGES, RESET_MESSAGES, REMOVE_MESSAGE, REMOVE_TEMP_MESSAGE } from '../actions/actions';
import { v4 as uuid } from 'uuid';

let localMessages: any = [];
const excludes = ['IMAGE', 'VIDEO', 'CONTACT', 'PRODUCT', 'NEW_NOTIFICATION', 'LIVE_CHAT_NOTIFICATION'];

if ((window as any).parent && (window as any).parent.BotPenguinData) {

  const activeTabCount = parseInt(localStorage.getItem('tabCount') || '0');

  localMessages = (window as any).parent.BotPenguinData.configuration?.generalSettings?.userChatRetention === 'forever' || activeTabCount
    ? localStorage.getItem(`BotPenguin-${(window as any).parent.BotPenguinData._id}`)
    : sessionStorage.getItem(`BotPenguin-${(window as any).parent.BotPenguinData._id}`);

  if (localMessages) {
    try {
      localMessages = JSON.parse(localMessages).messages || [];
    } catch (e) {
      localMessages = [];
    }
  }
}


const messageReducer = (state = localMessages || [], action: any) => {
  switch (action.type) {
    case INITIALIZE_LANDING_PAGE: {
      return action.payload;
    }
    case NEW_MESSAGE: {
      const messages = [...state, { ...action.payload }];      
      return messages;
    }
    case LOAD_MESSAGE: {
      const messages = [...state, ...action.payload];      
      return messages;
    }
    case RESET_MESSAGES: {
      return [];
    }
    case UPDATE_MESSAGES: {
      const newState = JSON.parse(JSON.stringify(state));
      const messages = [...newState.map((message: any) => {
        if (!excludes.includes(message.type.toUpperCase())) {
          message.type = 'STATEMENT';
          message.back = false;
          message.skip = false;
        }
        return message;
      })];      
      return messages;
    }
    case UPDATE_SINGLE_MESSAGES: {
      const messages = [...state.map((message: any) => {
        if (message.mid === action.payload.mid) {
          return action.payload;
        }

        return message;
      })];      
      return messages;
    }
    case SYSTEM_MESSAGE: {
      let messages = [...state, {
        ...action.payload,
        type: 'system',
        mid: uuid()
      }];      
      return messages;
    }
    case LOAD_MESSAGES: {
      return action.payload || [];
    }
    case REMOVE_MESSAGE: {
      let messages = [ ...state.filter((message: any) => message.mid !== action.payload.mid)];
      return messages;
    }
    case REMOVE_TEMP_MESSAGE: {
      let messages = [ ...state.filter((message: any) => !message.isTemp)];
      return messages;
    }

    default: {
      return state;
    }
  }
};

export { messageReducer };
