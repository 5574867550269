import { LOAD_ENVIRONMENT, RESET_STREAMING_STATE, UPDATE_AI_ENVIRONMENT, UPDATE_AUDIO_INPUTS, UPDATE_ENVIRONMENT } from '../actions/actions';

let initialState: any = {};

const activeTabCount = parseInt(localStorage.getItem('tabCount') || '0');
const userChatRetention = (window as any)?.parent?.BotPenguinData?.configuration?.generalSettings?.userChatRetention;

let environment: any = userChatRetention === 'forever' || activeTabCount
  ? localStorage.getItem(`BotPenguin-${(window as any)?.parent?.BotPenguinData?._id}`)
  : sessionStorage.getItem(`BotPenguin-${(window as any)?.parent?.BotPenguinData?._id}`);

if ((window as any)?.parent?.BotPenguinData && environment) {
  initialState = JSON.parse(environment).environment;
} else if ((window as any)?.parent?.BotPenguinData && !environment) {
  initialState = {
    _id: (window as any).parent.BotPenguinData._id,
    _user: (window as any).parent.BotPenguinData._user,
    activeQuestion: null,
    activeFlow: (window as any).parent.BotPenguinData.flows[0].id,
    preview: (window as any).parent.BotPenguinData.preview || false,
    embedded: (window as any).parent.BotPenguinData.embedded || false,
    refresh: true,
    typing: false,
    isAiResponding: false,
    pause: false,
    activeQuestionType: 'statement',
    skip: false,
    back: false,
    dateTime: false,
    uuid: (window as any).parent.BotPenguinData.uuid,
    lastQuestion: '',
    profile: (window as any).parent.BotPenguinData.profile || '',
    plan: (window as any).parent.BotPenguinData.plan || '',
    liveChat: false,
    isLanding: (window as any).parent.BotPenguinData.isLanding,
    whitelabel: (window as any).parent.BotPenguinData.whitelabel || {},
    chatRequestAccepted: false,
    chatRequestRejected: false,
    _chatWindowUser: (window as any).parent.BotPenguinData._chatWindowUser,
    agentId: undefined,
    socketDisconnected: false,
    installations: (window as any).parent.BotPenguinData.installations || [],
    agencyData: (window as any).parent.BotPenguinData.agency || {},
    chatMenu: (window as any).parent.BotPenguinData.configuration.chatMenu,
    activeInput: (window as any).parent.BotPenguinData.configuration.chatMenu?.voiceSupport?.isEnabled &&
     (window as any).parent.BotPenguinData.configuration.chatMenu?.voiceSupport?.isDefault ? 'voice' : 'chat',
    _flow: (window as any).parent.BotPenguinData.flows[0]._id,
    customAttributesList: [],
    agentAvatar: '',
    agentName: '',
    viewType: '',
    selectedTags:[],
    direction: '',
    lastSentSuggestions: [],
    imagePreview: {
      isActive: false,
      src: ''
    },
    ai: {
      streaming: false,
      sequenceBuffer: {},
      nextExpectedSequence: 0,
      lastExpectedSequence: -1,
    },
    batchedMessages: '',
    timerRef: null,
    isResponseStreamingEnabled: (window as any).parent.BotPenguinData.isResponseStreamingEnabled ??  false,
    isVoiceOutputEnabled: false,
    audioInput: {
      isMicOn: false,
      isListening: false
    }
  };
} else {
  initialState = {};
}

const environmentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_ENVIRONMENT: {
      const updatedEnvironment = { ...state, ...action.payload };      
      return updatedEnvironment;
    }
    case LOAD_ENVIRONMENT: {
      return action.payload;
    }
    case RESET_STREAMING_STATE: {
      return {
        ...state,
        ai: {
          sequenceBuffer: {},
          nextExpectedSequence: 0,
          lastExpectedSequence: -1,
          streaming: false,
        }
      };
    }
    case UPDATE_AI_ENVIRONMENT: {
        const updatedEnvironment = {
          ...state,
          ai: {
            ...state.ai,
            ...action.payload
          }
        };
        return updatedEnvironment;
    }
    case UPDATE_AUDIO_INPUTS: {
      const updatedEnvironment = {
        ...state,
        audioInput: {
          ...state.audioInputs,
          ...action.payload
        }
      };
      return updatedEnvironment;
    }
    default: {
      return state;
    }
  }
};


export { environmentReducer };
