import { LOAD_ENVIRONMENT, RESET_STREAMING_STATE, UPDATE_AI_ENVIRONMENT, UPDATE_AUDIO_INPUTS, UPDATE_ENVIRONMENT } from './actions';

export const updateEnvironment = (payload: any) => ({
  type: UPDATE_ENVIRONMENT,
  payload
});

export const loadEnvironment = (payload: any) => ({
  type: LOAD_ENVIRONMENT,
  payload
});

export const resetStreamingState = () => ({
  type: RESET_STREAMING_STATE,
});

export const updateAiEnvironment = (payload: IUpdateAiEnvironment) => ({
  type: UPDATE_AI_ENVIRONMENT,
  payload
});

export const updateAudioInputsState = (payload: IAudioInputs) => ({
  type: UPDATE_AUDIO_INPUTS,
  payload
});

interface IUpdateAiEnvironment {
  streaming?: boolean;
  sequenceBuffer?: any;
  nextExpectedSequence?: number;
  lastExpectedSequenceInternal?: number;
}

interface IAudioInputs {
  isMicOn?: boolean;
  isListening?: boolean;
}