import { IQuestion } from '../../interfaces/Question';
import { findQuestions, replaceAttributeWithValueInObject } from '../Response';
import { v4 as uuid } from 'uuid';
import { createNewReference, focus, scroll, sleep } from '../../services/shared';
import { STORE } from '../../store';
import { updateAiEnvironment, updateEnvironment } from '../../actions/environment.action';
import { newMessage, updateAI, updateTextInput } from '../../actions';
import { emit } from '../../services/socket';
import { encryptPayload, saveMessage } from '../../services/saveResponse';
import { requestLivechat } from '../LiveChat';
import { validateAiResponse } from '../../services/ai';
import { getFinalAiQuestion, handleInvalidAiResponse } from '../AI';
import { renderAppointmentQue } from '../Appointments/Appointment-Question';
import { getNextQuestion } from '../Appointments/Common';
import { httpRequest } from '../../services/network';
const keyboard = ['QUESTION', 'EMAIL', 'PHONE', 'NUMBER', 'LOCATION', 'NAME', 'LIVE_CHAT', 'SMART_QUESTION', 'AI'];

export const renderQuestion = async (index = 0, aiResponse: any = '', isRestart = false, MID = '') => {
  try {
    const state: any = STORE.getState();
    let isLastAIQuestion = false;
    let activeQuestion: IQuestion = state.environment.activeQuestion;

    if (isRestart || index === 0) {
      STORE.dispatch(updateEnvironment({
        lastSentSuggestions: []
      }));
    }

    STORE.dispatch(updateEnvironment({
      typing: false,
      refresh: true,
      iframeActive: {},
    }));

    STORE.dispatch(updateTextInput({
      status: false
    }));

    let messages: any = await findQuestions(state.flows[0]?.questions, index);
    if (!messages.length) {
      emit('update-user-details', {
        isCompleted: true
      });
    } else {
      if (messages[messages.length - 1].type.toLowerCase() === 'statement') {
        emit('update-user-details', {
          isCompleted: true
        });
      }

      // This is introduced to stream the first message for AI comp
      if (messages.find((message: any) => message.type.toLowerCase() === 'ai')) {
        if (state.environment.isResponseStreamingEnabled && !MID) {
          STORE.dispatch(updateAiEnvironment({ streaming: true }));
        }
        if (state.environment?.chatMenu?.voiceSupport?.isEnabled) {
          STORE.dispatch(updateEnvironment({ isVoiceOutputEnabled: true }));
        }
      }
    }

    if (activeQuestion?.type === 'AI' && !isRestart) {
      const aiReplies = state.ai.aiReplies || 0;

      let resetAIReplies = false;
      if (aiReplies < (activeQuestion.queriesToHandle || 0)) {

        const { gptAnswer, suggestions }: any = aiResponse;
        const { aiConfig } = activeQuestion;

        let finalAiQuestion: any;

        if (suggestions && (suggestions || []).length) {
          STORE.dispatch(updateEnvironment({
            lastSentSuggestions: suggestions
          }));
        }

        const isValidResponse: any = validateAiResponse(aiResponse);

        isLastAIQuestion = (aiReplies === (activeQuestion.queriesToHandle - 1));

        /**
         * Author: Satyam Sharma
         * Date: 28 September
         * Description: 1. hide Suggestions if they are disabled or aiReplies have reached the last question of limit
         * 2. If intent is greeting send in the default ai suggestions
         * 3. If not send in the suggestions received in ai response API
         */
        const shouldHideSuggestion = !aiConfig?.isSuggestionsEnabled || isLastAIQuestion;

        let activeSuggestions = [];
        if (!shouldHideSuggestion) {
          if (aiResponse.intent === 'greeting') {
            activeSuggestions = aiConfig?.defaultSuggestions;
          } else {
            activeSuggestions = suggestions || [];
          }
        }

        if (!isValidResponse.valid) {
          let result = await handleInvalidAiResponse(isValidResponse);
          finalAiQuestion = await getFinalAiQuestion({ ...activeQuestion, suggestions: activeSuggestions }, result);
          if (finalAiQuestion.find((question: any) => question.type.toLowerCase() === 'ai')) {
            // for non streaming cases
            STORE.dispatch(updateEnvironment({ isVoiceOutputEnabled: true  }));
          }
        } else {
          finalAiQuestion = aiResponse
            ? [
              {
                ...activeQuestion,
                label: gptAnswer,
                delay: 0,
                type: 'AI',
                shouldFeedbackVisible: true,
                suggestions: activeSuggestions,
                _id: undefined,
                aiConfig: activeQuestion.aiConfig
              },
            ]
            : [];
        
          // for non streaming cases
          STORE.dispatch(updateEnvironment({ isVoiceOutputEnabled: true  }));
        }

        // If streaming is enabled for AI responses. Thee error/greetings will also be streamed.
        if (state.environment.isResponseStreamingEnabled
          && finalAiQuestion.find((item: any) => item.type.toLowerCase() === 'ai')) {
          STORE.dispatch(updateAiEnvironment({ streaming: true }));
        }

        resetAIReplies = !!aiResponse && aiReplies + 1 >= (activeQuestion.queriesToHandle || 0);

        messages = resetAIReplies
          ? isValidResponse.case === 'userdefined_intent'
            ? [...finalAiQuestion]
            : [...finalAiQuestion, ...messages]
          : ((finalAiQuestion || []).length ? finalAiQuestion : (messages || []));
      }

    }

    if ((messages || []).some((q: IQuestion) => q.type === 'AI' && !!q._id)) {
      STORE.dispatch(updateAI({
        aiReplies: 0
      }));

    } else if (messages.some((q: IQuestion) => q.type === 'AI')) {
      STORE.dispatch(updateAI({
        aiReplies: state.ai.aiReplies + 1
      }));
    }

    const appointmentHandlerIndex = messages.findIndex((m: any) => m.type === 'appointment' && m.appointmentHandler);
    const appointmentHandlerDetail = messages[appointmentHandlerIndex];

    if (appointmentHandlerDetail) {
      const response = await renderAppointmentQue(appointmentHandlerDetail);

      if (response.success) {
        messages.splice(appointmentHandlerIndex, 1, {
          ...appointmentHandlerDetail,
          label: appointmentHandlerDetail.label,
          options: response.options, type: 'button'
        });
      }

      if (!response.success && !(appointmentHandlerDetail.appointmentHandler === 'google' && response.message === 'Integration details not found')) {
        let messageText = appointmentHandlerDetail.message?.unavailable ||
          'Sorry, we are unable to process your request at this time. Please try again later.';

        let nextQuestion: Array<IQuestion> = await getNextQuestion(
          state.flows[0].questions,
          appointmentHandlerDetail
        );
        messages.splice(
          appointmentHandlerIndex,
          1,
          ...[
            {
              ...appointmentHandlerDetail,
              label: appointmentHandlerDetail.label,
              type: 'STATEMENT'
            },
            {
              ...appointmentHandlerDetail,
              label: messageText,
              type: 'STATEMENT'
            },
            ...(nextQuestion.length ? nextQuestion : [])
          ]
        );
      }
    }

    /**
     * Below we will render each message/question in messages array.
     */
    if (messages.length) {
      const updateState: any = STORE.getState();
      for await (let message of messages) {
        if (message.type && message.type === 'assign-chat') {
          httpRequest('POST', 'messenger/trigger-action', {
            binary: encryptPayload({
              ...message.chatAssignment,
              _bot: state.environment._id,
              _customer: state.environment._user,
              _subscriber: state.environment.uuid,
              _question: message._id as string,
              channel: 'WEBSITE'
            })
          })
            .catch(e => console.log(e));
        } else {

          if ('isLabelVisible' in message && !message.isLabelVisible) {
            message = createNewReference(message);
            message.label = '';
          }

          if (
            message.rephraseLabel &&
            (message.type !== 'AI' || (message.type === 'AI' && !!message._id))
          ) {
            message = createNewReference(message);
            const { environment } = STORE.getState();

            const rephrasedQue = (environment.rephrasedFlow || []).find((q: any) => q.id === message.id) ||
              (environment.rephrasedRevisitFlow || []).find((q: any) => q.id === message.id) ||
              (environment.rephrasedRedirectFlow || []).find((q: any) => q.id === message.id);

            if (message.label && rephrasedQue && rephrasedQue.rephrasedLabelList && rephrasedQue.rephrasedLabelList.length) {
              message.label = rephrasedQue.rephrasedLabelList[Math.floor(Math.random() * 10)];
            }
            if (message.secondaryLabel && rephrasedQue && rephrasedQue.rephrasedSecondaryLabelList && rephrasedQue.rephrasedSecondaryLabelList.length) {
              message.secondaryLabel = rephrasedQue.rephrasedSecondaryLabelList[Math.floor(Math.random() * 10)];
            }
          }

          message = replaceAttributeWithValueInObject(createNewReference(message), [...updateState.attributes]);
          let messageText = message?.label?.replace("{{name}}", sessionStorage.getItem('name') || 'User');

          STORE.dispatch(updateEnvironment({
            typing: true,
            refresh: false,
            skip: false,
            back: false
          }));

          await sleep(Math.abs(message.delay));
          scroll(message?.id + ' ' + (message.label || '').slice(-10));

          const mid = MID || uuid();
          saveMessage({
            type: 'message',
            text: messageText,
            messagedBy: 'bot',
            questionType: (message?.type || '').toLowerCase(),
            questionId: message?.id,
            isValidResponse: aiResponse?.isValidResponse ?? false,
            queryIntent: aiResponse?.intent === 'userdefined_intent' 
            ? (aiResponse?.scenario?.actionName).toLowerCase() 
            : aiResponse?.intent ?? '',
            aiResponseRelevance: aiResponse?.preCheckFlag,
            isFreeAIMessage: aiResponse && !state.ai?.isKeyActive,
            isErrorMessage: false,
            mid
          })
            .catch(console.log);

          emit('message', {
            text: messageText,
            messageBy: 'bot',
            mid
          });

          if (!MID) {
            STORE.dispatch(newMessage({ ...message, label: messageText, mid, createdAt: String(new Date()) }));
          }

          STORE.dispatch(updateEnvironment({
            activeQuestion: message,
            typing: false,
            activeQuestionType: message.type,
            refresh: true,
            skip: message.skip,
            back: message.back,
            activeMID: mid, // added to keep track of active message ( added for AI )
            liveChat: message.type.toUpperCase() === 'LIVE_CHAT'
          }));

          /**
           * Author: Satyam Sharma
           * Date: 28 September
           * Description: If the message has questionId, it is the first question and in the case 
           * we need to default suggestions to the component
           */
          if ((messages || []).some((q: IQuestion) => q.type === 'AI' && !!q._id) && message.aiConfig?.isSuggestionsEnabled && !isLastAIQuestion) {
            const lastSentSuggestions = STORE.getState().environment?.lastSentSuggestions || [];
            STORE.dispatch(updateEnvironment({
              activeQuestion: {
                ...message,
                suggestions: message.aiConfig?.useHistoryForDefaultSuggestions && lastSentSuggestions.length
                  ? lastSentSuggestions
                  : message?.aiConfig?.defaultSuggestions || []
              }
            }));
          }

          STORE.dispatch(updateTextInput({
            status: keyboard.includes(message.type.toUpperCase())
          }));

          scroll(message?.id + ' ' + (message.label || '').slice(-10));
          await sleep();
          focus();
          if (message.type.toUpperCase() === 'LIVE_CHAT') {
            requestLivechat();
          }

          if (message.type.toLowerCase() === 'iframe') {
            STORE.dispatch(updateEnvironment({
              iframeActive: { label: message.label, source: message.source, iframeCTA: message.iframeCTA, iframeTitle: message.iframeTitle }
            }));
          }
        }
      }
    }

    // Check if user is interacting with AI component and the user text is considered as a neutral text instead of a greeting or query
    if (!messages.length && activeQuestion?.type === 'AI') {
      STORE.dispatch(updateTextInput({
        status: true
      }));
    }

  } catch (error) {
    console.log(error, "Error in rendering question")
  }
}
