import { STORE } from '../../../store';
import { updateEnvironment } from '../../../actions/environment.action';
import { createPayload, handleEmitMessage } from '../Common';
import { updateAppointmentMeta } from '../../../actions';
import { handleIsMore } from '../Common';
import { getTimeSlots } from './dates-slots';
import { getDatesAndSlots } from '../Appointment-Question/dates-types';
import { makeSimplyBooking } from '../SimplyBook';
import { makeGhlAppointment } from '../GoHighLevel';
import { makeGcAppointment } from '../Google-Calendar';
import { createAcuityBooking } from '../Acuity';

/**
* The below handler will check for the following cases where the answer is related to appointment question,
* It will handle the following cases:
* 1 Once the date is selected by end user (i.e answer is date), it will render the slots for the selected date.
* 2 It can render remaining dates/slots/types if end user selects the More Options as answer.
* 3 Once the date and slot is selected it will internaly handle the appointment booking/creation.
*/

export const handleAppointment = async (answer: string): Promise<boolean> => {
	try {

		const state: any = STORE.getState();
		const activeQuestion: any = state.environment.activeQuestion;

		if (activeQuestion.type === 'button' && state.appointmentMeta.isActive) {

			const handlerType = activeQuestion.appointmentHandler;
			const moreOptions = activeQuestion.appointment.moreOption || 'More Option';
			const appointmentMeta = state.appointmentMeta;
			const { date, slot, acuityId } = appointmentMeta.selectedValues;
			const { slots, slotsSent, datesSent } = appointmentMeta;
			let messageText;
			STORE.dispatch(updateEnvironment({ typing: true }));

			/**
				* Date: 29/Jul/2024
				* Author: Rajan Grover
				* Summary: The below condition is specifically to handle the case for acuity. As in the case of acuity, after we have shown the user the appointment types,
				* the user can opt for more options or can select an appointment type. If the end user selects more options, then we need to send the next available options.
				* But if the user selects an appointment type, then we need to call the dates API for acuity, update our appointmentMeta, and show the dates.
				*/

			if (handlerType === 'acuity' && !acuityId) {
				const isMore = answer === moreOptions;

				// End user wants to see next/remaining acuity appointment types. 
				if (isMore) {
					messageText = activeQuestion.label || 'Please select an appointment type';
					handleIsMore('appointment', messageText, moreOptions);
					return true;
				}

				const response = await getDatesAndSlots(activeQuestion, answer);
				if (!response.success) {
					messageText = activeQuestion.message?.unavailable ||
						'Sorry, we are unable to process your request at this time. Please try again later.';
					handleEmitMessage(messageText, 'STATEMENT', 'message', [], 'bot', activeQuestion, true);
					return false;
				}
				// End user has selected the appointment type and now we need to render the dates for acuity.
				const optionsPayload = createPayload(response.availableDates, datesSent, moreOptions, 'date');
				STORE.dispatch(updateAppointmentMeta({
					...state.appointmentMeta,
					dates: response.availableDates,
					datesSent: datesSent + optionsPayload.length - 1,
					selectedValues: {
						acuityId: response.acuityId,
					}
				}));
				messageText = activeQuestion.label || activeQuestion.appointment?.secondaryLabel || 'Please select a date';
				handleEmitMessage(messageText, 'button', 'button', optionsPayload, 'bot', activeQuestion, false);
				return true;
			}

			/**
			 * Date: 29/Jul/2024
			 * Author: Rajan Grover
			 * Summary: The below logic is a general for all appointment handler types and will work after we have shown the end user the available dates one time.
			 * The end user can view more dates or select a date and then proceed with slot selection.
			 */

			if (!date && !slot) {
				const isMore = answer === moreOptions;
				if (isMore) {
					// Display remaining/Next dates
					messageText = activeQuestion.label || 'Please select a date';
					handleIsMore('date', messageText, moreOptions);
					return true;
				}

				// sleep(2);
				let response = await getTimeSlots(answer, handlerType, slots);
				if (!response.success) {
					messageText = activeQuestion.message?.unavailable
						|| 'Sorry, we are unable to process your request at this time. Please try again later.';

					handleEmitMessage(messageText, 'STATEMENT', 'message', [],'bot', activeQuestion, true);
					return false;
				}
				// Date has been selected by the end user now display him the time slots.
				let optionsPayload = createPayload(response.availableSlots, slotsSent, moreOptions, 'timeSlot', activeQuestion.next.target)
				STORE.dispatch(updateAppointmentMeta({
					...state.appointmentMeta, slotsSent: slotsSent + optionsPayload.length - 1,
					slots: response.availableSlots,
					selectedValues: {
						...state.appointmentMeta.selectedValues,
						date: answer
					}
				}));

				messageText = activeQuestion.appointment.secondaryLabel || activeQuestion.label || 'Please select a time slot';
				handleEmitMessage(messageText, 'button', 'button', optionsPayload, 'bot', activeQuestion, false);
				return true;
			} else if (date) {

				const isMore = answer === moreOptions;
				if (isMore) {
					// Display remaing slots
					messageText = activeQuestion.appointment.secondaryLabel || activeQuestion.label;
					handleIsMore('timeSlot', messageText, moreOptions);
					return true;

				} else {
					// Both date and time slot have been selected ,so proceed with appointment booking.
					createAppointment(handlerType, answer, slots);
					STORE.dispatch(updateEnvironment({ typing: false }));
					STORE.dispatch(updateAppointmentMeta({
						dates: [],
						datesSent: 0,
						slots: [],
						slotsSent: 0,
						acuityTypes: [],
						acuityTypesSent: 0,
						selectedValues: {
							date: '',
							slot: '',
							acuityId: ''
						},
						lastQuestionSent: {},
						isActive: false
					}));
					// Returning false as after booking we don't want to hold the flow, and want to proceed with next question.
					return false;
				}
			}

		}
		return false;
	} catch (error) {
		console.log('Error in appointment handling', error);
		return false;
	}

}

export const createAppointment = (handlerType: string, answer: string, slots: any) => {
	try {
		switch (handlerType) {
			case 'simply-book':
				const startTime = answer.split('-')[0];
				const selectedSlot = slots.find((s: { start: string }) => s.start === startTime);
				makeSimplyBooking(selectedSlot.id);
				break;
			case 'google':
				makeGcAppointment(answer);
				break;
			case 'go-high-level':
				makeGhlAppointment(answer);
				break;
			case 'acuity':
				createAcuityBooking(answer);
				break;
			default:
				throw new Error('Invalid handler type')
		}
	} catch (error) {
		console.log('Error in creating appointment', error);
	}
};


