import { NEW_MESSAGE, UPDATE_MESSAGES, UPDATE_SINGLE_MESSAGES, LOAD_MESSAGE, SYSTEM_MESSAGE, INITIALIZE_LANDING_PAGE, LOAD_MESSAGES, RESET_MESSAGES, REMOVE_MESSAGE, REMOVE_TEMP_MESSAGE } from './actions';

export const newMessage = (payload: any) => ({
  type: NEW_MESSAGE,
  payload
});

export const updateMessage = (payload: any) => ({
  type: UPDATE_MESSAGES,
  payload
});

export const loadMessages = (payload: any) => ({
  type: LOAD_MESSAGE,
  payload
});

export const restoreMessages = (payload: any) => ({
  type: LOAD_MESSAGES,
  payload
});

export const updateSingleMessage = (payload: any) => ({
  type: UPDATE_SINGLE_MESSAGES,
  payload
});

export const resetMessages = () => ({
  type: RESET_MESSAGES
});

export const removeMessage = (payload: any) => ({
  type: REMOVE_MESSAGE,
  payload
});

/**
 * Author: Satyam Sharma
 * Date: 28 Jan 2025
 * Description: This is used to remove temp messages added during speech.
 * Temp messages are the ones are the spoken but never sent and hence those are removed if the chat is refreshed.
 */

export const removeTempMessages = () => ({
  type: REMOVE_TEMP_MESSAGE,
});

export interface ISystemMessage {
  method: 'no-agent-found' | 'initiating-live-chat' | 'chat-request-accepted' | 'chat-restarted';
  title: string;
  titleColor?: string;
  subTitle: string;
  agentName?: string;
}

export const addSystemMessage = (payload: ISystemMessage) => ({
  type: SYSTEM_MESSAGE,
  payload
});

export const initializeMessages = (payload: any[]) => ({
  type: INITIALIZE_LANDING_PAGE,
  payload
});

